import { Suspense, lazy } from "react";
import "./App.css";

const Hero = lazy(() => import("./components/sections/Hero"));
const CV = lazy(() => import("./components/sections/CV"));
const Imagery = lazy(() => import("./components/sections/Imagery"));
const SideHustles = lazy(() => import("./components/sections/SideHustles"));
const About = lazy(() => import("./components/sections/About"));

function App() {
  return (
    <main className="overflow-hidden">
      <Suspense fallback={<div>Loading...</div>}>
        <Hero />
        <CV />
        <Imagery />
        <SideHustles />
        <About />
      </Suspense>
    </main>
  );
}

export default App;
